<template>
  <e-modal-custom-form
    ref="modalDeleteForm"
    title="Exclusão do pedido de compra"
    @after-confirm="$emit('after-confirm')"
  >
    <b-row>
      <b-col
        md="12"
        class="d-flex flex-column"
      >
        <FormulateInput
          id="delete_modal-reason"
          v-model="deleteForm.reason"
          name="reason"
          class="w-100 required"
          type="textarea"
          validation="required|min:15,length"
          rows="4"
          :label="$t('Motivo da exclusão')"
        />
      </b-col>
    </b-row>
  </e-modal-custom-form>
</template>

<script>
import EModalCustomForm from '@/views/components/EModalCustomForm.vue'
import { BCol, BRow } from 'bootstrap-vue'
import { mapActions } from 'vuex'

export default {
  name: "ModalDeletePurchaseOrder",

  components: {
    BCol,
    BRow,
    EModalCustomForm,
  },

  props: {},
  data() {
    return {
      deleteForm: {
        reason: ''
      }
    }
  },
  methods: {
    ...mapActions('pages/purchase/purchaseOrder/purchaseOrderMaintain', {
      stDeletePurchaseOrder: 'deletePurchaseOrder',
    }),

    async showModal(purchaseOrder) {
      if (!this.$can('Delete', 'PurchaseOrder')) {
        this.showWarning({ message: this.$t('without_permission') })
        return
      }

      if (
        await this.confirmHtml({
          title: '',
          html: `Confirma excluir pedido de compra #${purchaseOrder.id}? Esta ação não poderá ser revertida depois.`,
        })
      ) {
        this.$refs.modalDeleteForm.show(async () => {
          await this.stDeletePurchaseOrder({
            purchaseOrderId: purchaseOrder.id,
            reason: this.deleteForm.reason
          })
        })
      }
    },
  },
}
</script>
