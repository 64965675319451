var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "e-modal-custom-form",
    {
      ref: "modalDeleteForm",
      attrs: { title: "Exclusão do pedido de compra" },
      on: {
        "after-confirm": function ($event) {
          return _vm.$emit("after-confirm")
        },
      },
    },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { staticClass: "d-flex flex-column", attrs: { md: "12" } },
            [
              _c("FormulateInput", {
                staticClass: "w-100 required",
                attrs: {
                  id: "delete_modal-reason",
                  name: "reason",
                  type: "textarea",
                  validation: "required|min:15,length",
                  rows: "4",
                  label: _vm.$t("Motivo da exclusão"),
                },
                model: {
                  value: _vm.deleteForm.reason,
                  callback: function ($$v) {
                    _vm.$set(_vm.deleteForm, "reason", $$v)
                  },
                  expression: "deleteForm.reason",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }